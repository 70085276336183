.section {
  border-left: 1px solid rgb(235, 235, 235);
  width: 40%;
  height: 98%;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.sectionLabel {
  font-size: 21px;
  font-weight: 300;
}

.sectionTitle {
  font-size: 32px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.price {
  font-size: 18px;
}

.config {
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  gap: 16px;
}

.configItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.configItemTitle {
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.configItemList {
  display: flex;
  align-items: center;
  gap: 12px;
}

.configItemList div {
  position: relative;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 45px;
  width: 50px;
  height: 50px;
}

.selectedOption::after {
  border: 2px solid #49c6ba;
  position: absolute;
  bottom: -5px;
  left: 5%;
  content: " ";
  width: 90%;
  height: 2px;
}

@media (max-width: 720px) {
  .section {
    width: 100%;
  }
}
