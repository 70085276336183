#btnAr {
  position: absolute;
  left: 16px;
  top: 16px;
  width: fit-content;
  height: 32px;
  background-color: #49c6ba;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 16px;
  padding: 8px 12px;
  cursor: pointer;
}
